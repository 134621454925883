@import '../../../UI/global.module.scss';

.container {
	width: 100%;
	margin: 0 auto;
  padding: 32px 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 32px;
	background-color: #153B34;

	.containerCeo {

		.position {
			display: none;
		}
	}

	.ceo {
		width: 120px;
		min-width: 120px;
		height: 120px;
		border-radius: 20px;
		overflow: hidden;
	}
	
  .aboutCompany {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 32px;
		
		.icon {
			position: absolute;
			left: 100%;
			top: -85px;
		}
	}

  .description {
		font-size: 24px;
		font-weight: 400;
		line-height: 30px;
		letter-spacing: -0.5px;
		color: #FCFFFE;
		max-width: 726px;
  }
	
  .position {
		max-width: 726px;
		font-size: 24px;
		font-weight: 400;
		line-height: 30px;
		letter-spacing: -0.5px;
		color: #6BE3D7;
  }
	
}

.mainColor {
	color: $main-color;
}

@media only screen and (max-width: 768px) {
	.container {
		flex-direction: column-reverse;
		align-items: flex-start;

		.containerCeo {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 24px;

			.ceo {
				width: 64px;
				min-width: 64px;
				height: 64px;
				border-radius: 12px;
			}
			.position {
				display: flex;				
			}
		}

		.position {
			display: none;
			font-size: 16px;
			line-height: 22px;
			letter-spacing: -0.5px;
		}
		.description {
			font-size: 18px;
			line-height: 24px;
			letter-spacing: -0.5px;
		}
  }
}
